// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/activity/commons/ActivityCommonsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.activity.commons.ActivityCommonsTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../../commons/LinkTemplate.soy.generated.js';


/**
 * @param {!$userFullName.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $userFullName = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $userFullName$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.user, opt_data.username);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} user
 * @param {null|string|undefined=} username
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $userFullName$ = function($$areYouAnInternalCaller, $ijData, user, username) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.activity.commons.ActivityCommonsTemplate.userFullName']) {
    return soy.$$stubsMap['ts.perspectives.activity.commons.ActivityCommonsTemplate.userFullName']({username: username, user: user}, $ijData);
  }
  soy.assertParamType(username == null || typeof username === 'string', 'username', username, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(!username ? '<i' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.activity.commons.ActivityCommonsTemplate.userFullName ts/perspectives/activity/commons/ActivityCommonsTemplate.soy:16"' : '') + '>Unknown user</i>' : user ? '<a class="user-full-name" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.showUser$(soy.$$internalCallMarkerDoNotUse, $ijData, user[/** @type {?} */ ('username')]))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.activity.commons.ActivityCommonsTemplate.userFullName ts/perspectives/activity/commons/ActivityCommonsTemplate.soy:19"' : '') + '>' + (!user[/** @type {?} */ ('firstName')] && !user[/** @type {?} */ ('lastName')] ? soy.$$escapeHtml(username) : soy.$$escapeHtml(user[/** @type {?} */ ('firstName')]) + '&nbsp;' + soy.$$escapeHtml(user[/** @type {?} */ ('lastName')])) + '</a>' : soy.$$escapeHtml(username));
};
export { $userFullName as userFullName };
export { $userFullName$ as userFullName$ };
/**
 * @typedef {{
 *  username?: (null|string|undefined),
 *  user: ?,
 * }}
 */
$userFullName.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $userFullName.soyTemplateName = 'ts.perspectives.activity.commons.ActivityCommonsTemplate.userFullName';
}
